import * as React from 'react';
import { Query, QueryState } from 'react-contentful';
import { StaticMap } from 'react-map-gl';

import { ScatterplotLayer } from '@deck.gl/layers';
import DeckGL from '@deck.gl/react';

const MARKER_COLORS = {
  city: [172, 42, 246],
  place: [119, 50, 246],
  airport: [236, 53, 36],
  country: [0, 149, 94],
  neighborhood: [209, 160, 62],
  hotel: [255, 105, 45],
  park: [223, 230, 28],
};

const INITIAL_VIEW_STATE = {
  latitude: 31.6,
  longitude: -19.6,
  zoom: 2,
  maxZoom: 15,
  minZoom: 1.5,
  pitch: 30,
  bearing: 30,
};

const App: React.FC = () => {
  return (
    <Query contentType="place" query={{ limit: 300 }}>
      {({ data, error, fetched, loading }: QueryState) => {
        if (loading || !fetched) {
          return null;
        }

        if (error) {
          console.error(error);
          return null;
        }

        if (!data) {
          return <p>No places found.</p>;
        }

        const layers = [
          new ScatterplotLayer({
            id: 'scatterplot-layer',
            data: data.items,
            pickable: true,
            filled: true,
            radiusScale: 6,
            radiusMinPixels: 5,
            radiusMaxPixels: 20,
            getPosition: (d) => [d.fields.location.lon, d.fields.location.lat],
            getRadius: () => 5,
            getFillColor: (d) =>
              MARKER_COLORS[d.fields.type.fields.name.toLowerCase()],
          }),
        ];

        return (
          <DeckGL
            initialViewState={INITIAL_VIEW_STATE}
            controller={true}
            layers={layers}
          >
            <StaticMap
              width={'100%'}
              height={'100%'}
              mapStyle="mapbox://styles/fschultz/ck6faw97927g61ioh30mxkgxl"
              mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
            />
          </DeckGL>
        );
      }}
    </Query>
  );
};

export default App;

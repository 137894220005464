import './index.css';
import 'mapbox-gl/dist/mapbox-gl.css';

import * as React from 'react';
import {
  ContentfulClient,
  ContentfulClientInterface,
  ContentfulProvider,
} from 'react-contentful';
import { render } from 'react-dom';

import App from './app';

// @ts-ignore
const contentfulClient: ContentfulClientInterface = new ContentfulClient({
  accessToken: process.env.CONTENTFUL_ACCESS_TOKEN || '',
  space: process.env.CONTENTFUL_SPACE || '',
});

render(
  <ContentfulProvider client={contentfulClient}>
    <App />
  </ContentfulProvider>,
  document.getElementById('root')
);
